// Remove for Production

import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Components
import Seo from '@components/seo/Index'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

// CSS
import * as cx from './LeadContactForm.module.scss'

const LeadContactFormPage = ({ data: { page }, pageContext: { seo, uri } }) => {
  const [title, setTitle] = useState('Get a Free Consultation')
  const [subtitle, setSubtitle] = useState('We’d love to hear from you')
  const [submitLabel, setSubmitLabel] = useState('I want a stunning website!')
  const [version, setVersion] = useState('LCF01')
  const versions = [
    'LCF01',
    'LCF02',
    'LCF03',
    'LCF04',
    'LCF05',
    'LCF06',
    'LCF07',
    'LCF08',
  ]
  const [subVersion, setSubVersion] = useState('default')
  const subVersions = {
    LCF01: ['default'],
    LCF02: ['default'],
    LCF03: ['default'],
    LCF04: ['default', 'A', 'B'],
    LCF05: ['default', 'A'],
    LCF06: ['default', 'center', 'right'],
    LCF07: ['default'],
    LCF08: ['default', 'alt', 'B'],
  }
  const [model, setModel] = useState('Samantha')
  const models = [
    'Samantha',
    'Jada',
    'Allison',
    'Tiffany',
    'Elisse',
    'Nicole',
    'Dana',
    'Donna',
    'Jessica',
    'Shiela',
    'Petra',
    'Aaliyah',
    'Harvey',
    'Scott',
    'Alex',
    'Jeff',
    'Louis',
    'Nico',
    'David',
    'Keith',
    'Trevor',
    'Todd',
    'Natalie',
    'Britney',
    'Trevor',
    'Chad',
    'TrevorAlt',
    'Gerald',
    'GeraldAlt',
    'NicoAlt',
    'AaliyahAlt',
    'AllisonAlt',
    'Callie',
    'Cassie',
    'Cera',
    'Chadia',
    'Charlotte',
    'Davina',
    'Diana',
    'Emma',
    'Eva',
    'Gianna',
    'Hannah',
    'Jennifer',
    'Jodie',
    'Jordyn',
    'Kinsley',
    'Madison',
    'Megan',
    'Meredith',
    'MeredithAlt',
    'Monica',
    'Nisha',
    'PetraAlt',
    'Robyn',
    'Shelby',
  ]
  const lcfsix = [
    {
      name: 'Britney',
      value: 'LcfSixBritney',
    },
    {
      name: 'BritneyAlt',
      value: 'LcfSixBritneyAlt',
    },
    {
      name: 'Chad',
      value: 'LcfSixChad',
    },
    {
      name: 'GroupOne',
      value: 'LcfSixGroupOne',
    },
    {
      name: 'GroupTwo',
      value: 'LcfSixGroupTwo',
    },
    {
      name: 'GroupThree',
      value: 'LcfSixGroupThree',
    },
    {
      name: 'GroupFour',
      value: 'LcfSixGroupFour',
    },
    {
      name: 'Furniture',
      value: 'LcfSixFurniture',
    },
    {
      name: 'Emma',
      value: 'FullBgEmma',
    },
    {
      name: 'Heather',
      value: 'FullBgHeather',
    },
    {
      name: 'Kirstin',
      value: 'FullBgKirstin',
    },
    {
      name: 'Suri',
      value: 'FullBgSuri',
    },
    {
      name: 'Suri Alt',
      value: 'FullBgSuriAlt',
    },
  ]
  const lcfseven = [
    'TextureLightOne',
    'TextureLightTwo',
    'TextureLightThree',
    'TextureLightFour',
    'TextureLightFive',
    'TextureLightSix',
    'TextureBlueOne',
    'TextureBlueTwo',
    'TextureBlueThree',
  ]
  const lcfeight = [
    'CutOutElisse',
    'CutOutHarvey',
    'CutOutNicole',
    'CutOutCassie',
    'CutOutChadia',
    'CutOutKatie',
  ]

  const getTemplate = (selected) => {
    switch (selected) {
      case 'LCF06':
      case 'LCF07':
        return (
          <LeadContactForm
            version={version}
            background={model}
            title={title}
            subtitle={subtitle}
            submitLabel={submitLabel}
            leadName="Lead Contact Form"
            layout={subVersion}
          />
        )
      default:
        return (
          <LeadContactForm
            version={version}
            modelOrBackground={model}
            title={title}
            subtitle={subtitle}
            submitLabel={submitLabel}
            leadName="Lead Contact Form"
            layout={subVersion}
          />
        )
    }
  }

  const getTemplateRaw = (selected) => {
    switch (selected) {
      case 'LCF06':
      case 'LCF07':
        return (
          <>
            &lt;LeadContactForm <br />
            version="{version}"<br />
            background="{model}"<br />
            title="{title}"<br />
            subtitle="{subtitle}"<br />
            submitLabel="{submitLabel}"<br />
            leadName="Lead Contact Form"
            <br />
            layout="{subVersion}"<br />
            /&gt;
          </>
        )
      default:
        return (
          <>
            &lt;LeadContactForm <br />
            version="{version}"<br />
            modelOrBackground="{model}"<br />
            title="{title}"<br />
            subtitle="{subtitle}"<br />
            submitLabel="{submitLabel}"<br />
            leadName="Lead Contact Form"
            <br />
            layout="{subVersion}"<br />
            /&gt;
          </>
        )
    }
  }

  return (
    <>
      <Seo title={page.title} seo={seo} uri={uri} />
      <div className={cx.formController}>
        <div className="form-row">
          <div className="form-col">
            <div className="form-control">
              <label>Version</label>
              <select
                value={version}
                onChange={(e) => {
                  setVersion(e.target.value)

                  if (e.target.value === 'LCF06') {
                    setModel('Britney')
                  } else if (e.target.value === 'LCF07') {
                    setModel('TextureLightOne')
                  } else if (e.target.value === 'LCF08') {
                    setModel('CutOutElisse')
                  } else {
                    setModel('Samantha')
                  }

                  setSubVersion('default')
                }}
              >
                {versions.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="form-col">
            <div className="form-control">
              <label>Sub Version</label>
              <select
                value={subVersion}
                onChange={(e) => {
                  setSubVersion(e.target.value)
                }}
              >
                {subVersions[version].map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          {(version === 'LCF01' ||
            version === 'LCF02' ||
            version === 'LCF03' ||
            version === 'LCF04' ||
            version === 'LCF05') && (
            <div className="form-col">
              <div className="form-control">
                <label>Select Model</label>
                <select
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value)
                  }}
                >
                  {models.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
          {version === 'LCF06' && (
            <div className="form-col">
              <div className="form-control">
                <label>Select Background</label>
                <select
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value)
                  }}
                >
                  {lcfsix.map((item, index) => {
                    return (
                      <option key={index} value={item['value']}>
                        {item['name']}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
          {version === 'LCF07' && (
            <div className="form-col">
              <div className="form-control">
                <label>Select Background</label>
                <select
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value)
                  }}
                >
                  {lcfseven.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
          {version === 'LCF08' && (
            <div className="form-col">
              <div className="form-control">
                <label>Select Background</label>
                <select
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value)
                  }}
                >
                  {lcfeight.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
          <div className={`form-col ${cx.code}`}>
            <div className={`form-control`}>
              <label>Component Code</label>
              <code>{getTemplateRaw(version)}</code>
            </div>
          </div>
        </div>
      </div>

      {getTemplate(version)}
    </>
  )
}

export default LeadContactFormPage

export const LeadContactFormPagePageQuery = graphql`
  query LeadContactFormPagePageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
    }
  }
`
